

import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { calculateDatesEDT } from "@/app/_utils/others/Helpers";
import { ExportUsFinanceReportAction, GetCustomUsFinanceReportAction, GetHourlyUsFinanceReportAction, GetUSFinanceReportFiltersAction } from "./UsFinanceAction";
import { GetHourlyLiveReportAction } from "../Report/ReportAction";
import moment from "moment";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});


const getCurrentDateRange = () => {
  const timezone = "America/New_York";
  const today = moment().tz(timezone).startOf('day');
  const formattedToday = today.format("YYYY-MM-DD");
  // console.log('EDT Current Date:', formattedToday);
  return { 
    from_date: formattedToday, 
    to_date: formattedToday,
    selectedOption: "Today" 
  };
};


const getInitialTabState = () => ({
  duration: { date: true, month: false },
  indicator: {
    feedProvider: true,
    ads_creatives: true,
    adset: true,
    channel_identifier: true,
    media_buyer: true,
    impression: true,
    conversions:true,
    clicks: true,
    channel_Name:true,
  },
  calculation: {
    cpc: true,
    cpm: true,
    spend: true,
    margin: true,
    revenue: true,
    epc: true,
    epv: true,
    cpa: true,
    lpctr: true,
    margin_percent: true,
    average_payout: true,
  },
  filter: {
    channel_name: true,
    mediaBuyer: true,
    feed_provider: true,
  },
  filterOptions: {
    feed_provider: [],
    mediaBuyer: [],
    channel_name: [],

  },
  selectedOptions: {
    feed_provider: "",
    mediaBuyer: "",
    channel_name: "",

  },
  date_range: {
    from_date: getCurrentDateRange().from_date,
    to_date: getCurrentDateRange().to_date,
    selectedOption: "Today",
  },
  sortby: { order: "", sorting: "" },
});


 

  

    const formatOpFeed = (options) =>
    options.map((option) => ({
      value: option.value,
     label: option.label ,
    }));

    const formatOpMedibuyer = (options) =>
    options.map((option) => ({
      value: option.user_id,
     label: option.name ,
    }));



const initialState = {
  GetUSfinanceFilterdata: getInitialStatus(),
  GetCustomUSfinancedata: getInitialStatus(),
  GetHourlyUSfinancedata:getInitialStatus(),
  GetFinanceExportdata:getInitialStatus(),
  UsFinanceData:{},
  USfinnaceStatsData:{},
  HourlyReportData:{},
  ExportAllData:{},
  totalRecords:0,
  HourlytotalRecords:0,

  filters: {
    1: getInitialTabState(),
    2: getInitialTabState(),
    3: getInitialTabState(),
    4: getInitialTabState(),
    5: getInitialTabState(),
  },
  activeTab: 1,
  tabNames: {
    1: "Custom Report 1",
    2: "Custom Report 2",
    3: "Custom Report 3",
    4: "Custom Report 4",
    5: "Custom Report 5",
  },
};

const UsFinanceSlice = createSlice({
  name: "GetUsfinance",
  initialState,

  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setFilter: (state, action) => {
      const { tabId, filterKey, filterValue } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId][filterKey] = filterValue;
      }
    },
    setFilterOption: (state, action) => {
      const { tabId, filterKey, filterOptions } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].filterOptions[filterKey] = filterOptions;
      }
    },
    setSelectedOptions: (state, action) => {
      const { tabId, selectedOptions } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].selectedOptions = selectedOptions;
      }
    },
    setFiltersForActiveTab: (state, action) => {
      state.filters[state.activeTab] = action.payload;
    },
    setFilterData: (state, action) => {
      const { filters } = action.payload;
      state.filters = filters;
    },
    renameTab: (state, action) => {
      const { tabId, newName } = action.payload;
      if (state.tabNames[tabId]) {
        state.tabNames[tabId] = newName;
      }
    },
    setTabNames: (state, action) => {
      state.tabNames = action.payload;
    },
    setSort: (state, action) => {
      const { tabId, sortField, sortOrder } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].sortby = { sorting: sortField, order: sortOrder };
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetUSFinanceReportFiltersAction.pending, (state) => {
      state.GetUSfinanceFilterdata.isLoading = true;
    });
    builder.addCase(GetUSFinanceReportFiltersAction.fulfilled, (state, action) => {
      state.GetUSfinanceFilterdata.isLoading = false;
      state.GetUSfinanceFilterdata.isSuccess = true;

      const { response } = action.payload;
      const updatedFilters = {};
      const tabIds = [1, 2, 3, 4, 5];

      tabIds.forEach((tabId) => {
        const tabData = response.data?.[0]?.[`custom_report_${tabId}`] || {};
        const dateRangeData = tabData.date_range || {
          selectedOption: "Today",
          from_date: "",
          to_date: "",
        };

        // console.log('tabData.sortby',tabData);
        

        // const dateRange = calculateDates(dateRangeData.selectedOption || 'Today');
        // console.log(`Tab ${tabId} dateRange:`, dateRange);

        let dateRange;
        // console.log("dateRangeData", dateRangeData.selectedOption);

        if (dateRangeData.selectedOption === "Custom Range") {
          // console.log(
          //   "dateRangeData.selectedOption === ",
          //   dateRangeData.selectedOption === "Custom Range"
          // );
          dateRange = {
            from_date: dateRangeData.from_date || getCurrentDateRange().from_date,
            to_date: dateRangeData.to_date || getCurrentDateRange().to_date,
            selectedOption: "Custom Range",
          };
        } else {
          dateRange = calculateDatesEDT(dateRangeData.selectedOption || "Today");
        }

        updatedFilters[tabId] = {
          duration:
            response.data?.[0]?.[`custom_report_${tabId}`]?.duration ||
            getInitialTabState().duration,
          indicator:
            response.data?.[0]?.[`custom_report_${tabId}`]?.indicator ||
            getInitialTabState().indicator,
          calculation:
            response.data?.[0]?.[`custom_report_${tabId}`]?.calculation ||
            getInitialTabState().calculation,
          filter:
            response.data?.[0]?.[`custom_report_${tabId}`]?.filter ||
            getInitialTabState().filter,
          filterOptions: {
            feed_provider: formatOpFeed(response.feed_provider) || [],
            mediaBuyer: formatOpMedibuyer(response.media_buyer || []),
            channel_name: response.uniqueChannelNames || [],
          },
          selectedOptions:
            tabData.selected_options || getInitialTabState().selectedOptions,
          date_range: dateRange,
          sortby: tabData.sortby || getInitialTabState().sortby,
        };
      });

      state.filters = updatedFilters;
      state.tabNames = tabIds.reduce((acc, tabId) => {
        acc[tabId] =
          response.data?.[0]?.[`custom_report_${tabId}`]?.report_name ||
          `Custom Report ${tabId}`;
        return acc;
      }, {});
    });

  
    builder.addCase(GetUSFinanceReportFiltersAction.rejected, (state, action) => {
      state.GetUSfinanceFilterdata.isLoading = false;
      state.GetUSfinanceFilterdata.isError = true;
      state.GetUSfinanceFilterdata.errorMsg = action.payload?.response;
      state.GetUSfinanceFilterdata.errorResponse = action.payload;
    });


    // get custom finnace report action
    builder.addCase(GetCustomUsFinanceReportAction.pending, (state) => {
      state.GetCustomUSfinancedata.isLoading = true;
    });
    builder.addCase(GetCustomUsFinanceReportAction.fulfilled, (state, action) => {
      state.GetCustomUSfinancedata.isLoading = false;
      state.GetCustomUSfinancedata.isSuccess = true;
      state.UsFinanceData = action.payload?.finalData;
      state.USfinnaceStatsData = action.payload?.sumTotal;
      state.totalRecords = action.payload?.total_length;
    });
    builder.addCase(GetCustomUsFinanceReportAction.rejected, (state, action) => {
      state.GetCustomUSfinancedata.isLoading = false;
      state.GetCustomUSfinancedata.isError = true;
      state.GetCustomUSfinancedata.errorMsg = action.payload?.response;
      state.GetCustomUSfinancedata.errorResponse = action.payload;
    });
    // Hourly
    builder.addCase(GetHourlyUsFinanceReportAction.pending, (state) => {
      state.GetHourlyUSfinancedata.isLoading = true;
    });
    builder.addCase(GetHourlyUsFinanceReportAction.fulfilled, (state, action) => {
      state.GetHourlyUSfinancedata.isLoading = false;
      state.GetHourlyUSfinancedata.isSuccess = true;
      state.GetHourlyUSfinancedata = action.payload;
      state.HourlyReportData = action.payload?.finalData;
      state.HourlytotalRecords = action.payload?.total_length;
    });
    builder.addCase(GetHourlyUsFinanceReportAction.rejected, (state, action) => {
      state.GetHourlyUSfinancedata.isLoading = false;
      state.GetHourlyUSfinancedata.isError = true;
      state.GetHourlyUSfinancedata.errorMsg = action.payload?.response;
      state.GetHourlyUSfinancedata.errorResponse = action.payload;
    });

    // export 

    builder.addCase(ExportUsFinanceReportAction.pending, (state) => {
      state.GetFinanceExportdata.isLoading = true;
    });
    builder.addCase(ExportUsFinanceReportAction.fulfilled, (state, action) => {
      state.GetFinanceExportdata.isLoading = false;
      state.GetFinanceExportdata.isSuccess = true;
      state.ExportAllData = action.payload;
    });
    builder.addCase(ExportUsFinanceReportAction.rejected, (state, action) => {
      state.GetFinanceExportdata.isLoading = false;
      state.GetFinanceExportdata.isError = true;
      state.GetFinanceExportdata.errorMsg = action.payload?.response;
      state.GetFinanceExportdata.errorResponse = action.payload;
    });

   
  },
});

export const {
  setActiveTab,
  setFilter,
  setFilterOption,
  setSelectedOptions,
  setFiltersForActiveTab,
  setFilterData,
  renameTab,
  setTabNames,
  setSort,
} = UsFinanceSlice.actions;

export default UsFinanceSlice.reducer;
