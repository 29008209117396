import { configureStore } from "@reduxjs/toolkit";

import UserAuthSlice from "./features/UserAuth/UserAuthSlice";
import ManageUserSlice from "./features/ManageUser/ManageUserSlice";
import ManagesettingSlice from "./features/Settings/ManagesettingSlice";
import ManageSiteSlice from "./features/Managesites/ManageSiteSlice";
import DashboardSlice from "./features/Dashboard/DashboardSlice";
import ReportSlice from "./features/Report/ReportSlice";
import FilterSlice from "./features/Filter/FilterSlice";
import SummaryReportSlice from "./features/SummaryReport/SummaryReportSlice";
import ActivityLogSlice from "./features/ActivityLog/ActivityLogSlice";
import ManageChannelSlice from "./features/ManageChannel/ManageChannelSlice";
import ManageIPSlice from "./features/ManageIP/ManageIPSlice"
import UsFinanceSlice from "./features/UsFinance/UsFinanceSlice"
import USDashboardSlice from "./features/USDashboard/USDashboardSlice"
import DiscoverySlice from "./features/Discovery/DiscoverySlice"
import ManageDomainSlice from "./features/ManageDomain/ManageDomainSlice"
import { RuleSlice } from "./features/RuleSlice/RuleSlice";

export const store =configureStore({
    reducer:{
        userAuth:UserAuthSlice,
        manageUser:ManageUserSlice,
        manageSetting:ManagesettingSlice,
        manageSites:ManageSiteSlice,
        GetUSDashboard:USDashboardSlice,
        GetDashboard:DashboardSlice,
        GetReport:ReportSlice,
        GetSummary:SummaryReportSlice,
        ActivityLog:ActivityLogSlice,
        ManageChannel: ManageChannelSlice,
        ManageIP:ManageIPSlice,
        GetUsfinance:UsFinanceSlice,
        ManageDiscovery:DiscoverySlice,
        ManageDomains:ManageDomainSlice,


        // 
        filter:FilterSlice,
        // rule
        rule: RuleSlice.reducer
        
    }
})


