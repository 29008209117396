
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activateCampaign: {
    selectedRule: '',
    ruleGroups: [],
    selectedDataInterval: '',
    selectedDefaultInterval: '',
    groupName: ''
  },
  ruleCondition: {
    rules: [{
      selectedOption: null,
      SecondSelectOption: null,
      selectedPerRDoller: null,
      selectedOnThenFie: null,
      numberValue: '',
      dateValue: null
    }]
  },
  adSetFiltering: {
    selectedPlacements: [],
    customFilters: []
  },
  applyRule: {
    action: '',
    parameters: {}
  },
  scheduleRule: {
    executeInterval: '',
    type: 'Execute',
    notifications: {
      email: false,
      slack: false,
      telegram: false
    }
  }
};

export const RuleSlice = createSlice({
  name: 'rule',
  initialState,
  reducers: {
    setActivateCampaignField: (state, action) => {
      const { field, value } = action.payload;
      state.activateCampaign[field] = value;
    },
    setRuleConditionField: (state, action) => {
      const { index, field, value } = action.payload;
      if (state.ruleCondition.rules[index]) {
        state.ruleCondition.rules[index][field] = value;
      }
    },
    setScheduleRuleField: (state, action) => {
      const { field, value } = action.payload;
      state.scheduleRule[field] = value;
    },
    setAdSetFilteringField: (state, action) => {
      const { field, value } = action.payload;
      state.adSetFiltering[field] = value;
    },
    setApplyRuleField: (state, action) => {
      const { field, value } = action.payload;
      state.applyRule[field] = value;
    },
    addRuleCondition: (state) => {
      state.ruleCondition.rules.push({
        selectedOption: null,
        SecondSelectOption: null,
        selectedPerRDoller: null,
        selectedOnThenFie: null,
        numberValue: '',
        dateValue: null
      });
    },
    deleteRuleCondition: (state, action) => {
      const { index } = action.payload;
      if (state.ruleCondition.rules.length > 1) {
        state.ruleCondition.rules.splice(index, 1);
      }
    },
    copyRuleCondition: (state, action) => {
      const { index } = action.payload;
      if (state.ruleCondition.rules[index]) {
        const copiedRule = { ...state.ruleCondition.rules[index] };
        state.ruleCondition.rules.push(copiedRule);
      }
    },
    resetForm: (state) => {
      return initialState;
    }
  }
});

export const {
  setActivateCampaignField,
  setRuleConditionField,
  setScheduleRuleField,
  setAdSetFilteringField,
  setApplyRuleField,
  addRuleCondition,
  deleteRuleCondition,
  copyRuleCondition,
  resetForm
} = RuleSlice.actions;

export default RuleSlice.reducer;