import { createSlice } from "@reduxjs/toolkit";
import {
  manageAccountGetAllAction,
  manageuserdeatails,
  Createuserdeatails,
  Deleteuserdeatails,
  GetProviderOption,
} from "./ManageUserAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getManageUser: getInitialStatus(),
  getsingleUserDeatails: getInitialStatus(),
  createUserInfo: getInitialStatus(),
  DeleteUserInfo: getInitialStatus(),
  ProviderOption: getInitialStatus(),
  userList: [],
  ProviderOption: [],
  ChennalIdeniferOption: [],
  singleUserData: {},
  totalCount: 0,
};

const manageUserSlice = createSlice({
  name: "manageUser",
  initialState,
  reducers: {
    resetManageUser: (state) => {
      state.getManageUser = getInitialStatus();
      state.getsingleUserDeatails = getInitialStatus();
      state.createUserInfo = getInitialStatus();
      state.DeleteUserInfo = getInitialStatus();
    },
    resetValueManageUser: (state) => {
      state.getManageUser = getInitialStatus();
      state.getsingleUserDeatails = getInitialStatus();
      state.createUserInfo = getInitialStatus();
      state.DeleteUserInfo = getInitialStatus();
      state.userList = [];
      state.singleUserData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(manageAccountGetAllAction.pending, (state) => {
      state.getManageUser = getInitialStatus();
      state.getManageUser.isLoading = true;
    });
    builder.addCase(manageAccountGetAllAction.rejected, (state, action) => {
      state.getManageUser = getInitialStatus();
      state.getManageUser.isError = true;
      const { response, message } = action.payload.data || {};
      state.getManageUser.errorResponse = message;

    });
    builder.addCase(manageAccountGetAllAction.fulfilled, (state, action) => {
      state.getManageUser = getInitialStatus();
      state.getManageUser.isSuccess = true;
      const responseData = action.payload?.response || [];
      // console.log('responseData',responseData);
      
    const totalCount = action.payload?.total_count || 0;
    const message = action.payload?.message || ''
    state.getManageUser.successMsg = message;
    state.userList = responseData;
    state.totalCount = totalCount;
    });
    builder.addCase(manageuserdeatails.pending, (state) => {
      state.getsingleUserDeatails = getInitialStatus();
      state.getsingleUserDeatails.isLoading = true;
    });
    builder.addCase(manageuserdeatails.rejected, (state, action) => {
      state.getsingleUserDeatails = getInitialStatus();
      state.getsingleUserDeatails.isError = true;
      state.getsingleUserDeatails.errorResponse = action.payload;
      // console.log("error log", state.getsingleUserDeatails.errorResponse);
    });
    builder.addCase(manageuserdeatails.fulfilled, (state, action) => {
      state.getsingleUserDeatails = getInitialStatus();
      state.getsingleUserDeatails.isError = false;
      state.getsingleUserDeatails.isSuccess = true;
      const { response, message } = action.payload || {};
      state.getsingleUserDeatails.successMsg = message;
      state.singleUserData = response || [];
    });
    // add new user
    builder.addCase(Createuserdeatails.pending, (state) => {
      state.createUserInfo = getInitialStatus();
      state.createUserInfo.isLoading = true;
    });
    builder.addCase(Createuserdeatails.rejected, (state, action) => {
      state.createUserInfo = getInitialStatus();
      state.createUserInfo.isError = true;
      const { response, message } = action.payload?.data || {};
      state.createUserInfo.errorResponse = response;
      state.createUserInfo.errorMsg = message;
    });
    builder.addCase(Createuserdeatails.fulfilled, (state, action) => {
      state.createUserInfo = getInitialStatus();
      state.createUserInfo.isSuccess = true;
      const { response, message } = action.payload || {};
      state.createUserInfo.successMsg = message;
      state.userList = [...state.userList, response];
    });
    // delete user
    builder.addCase(Deleteuserdeatails.pending, (state) => {
      state.DeleteUserInfo = getInitialStatus();
      state.DeleteUserInfo.isLoading = true;
    });
    builder.addCase(Deleteuserdeatails.rejected, (state, action) => {
      state.DeleteUserInfo = getInitialStatus();
      state.DeleteUserInfo.isError = true;
      state.DeleteUserInfo.errorResponse = action.payload;
    });
    builder.addCase(Deleteuserdeatails.fulfilled, (state, action) => {
      state.DeleteUserInfo = getInitialStatus();
      state.DeleteUserInfo.isSuccess = true;
      const { response, message } = action.payload || {};
      state.DeleteUserInfo.successMsg = message;
      state.DeleteUserInfo.errorResponse = response;
    });
    // GetProviderOption
    builder.addCase(GetProviderOption.pending, (state) => {
      state.ProviderOption = getInitialStatus();
      state.ProviderOption.isLoading = true;
    });
    builder.addCase(GetProviderOption.rejected, (state, action) => {
      state.ProviderOption = getInitialStatus();
      state.ProviderOption.isError = true;
      const errorResponse = action.payload;
      state.ProviderOption.errorResponse = errorResponse.data;
      state.ProviderOption.errorMsg = errorResponse.message;
    });

    builder.addCase(GetProviderOption.fulfilled, (state, action) => {
      state.ProviderOption = getInitialStatus();
      state.ProviderOption.isSuccess = true;
      const { response, message, identifier_data, provider_data } =
        action.payload || {};
      state.ProviderOption.successMsg = message;
      state.ChennalIdeniferOption = identifier_data[0].channel_identifier_name;
      state.ProviderOption = provider_data;
    });
  },
});

export const { resetManageUser, resetValueManageUser } =
  manageUserSlice.actions;
export default manageUserSlice.reducer;
