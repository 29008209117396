import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
const baseURL = process.env.NEXT_PUBLIC_API_URL;
export const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Axios Error:", error);
    const { status } = error.response || {};

    if (status === 401) {
      toast.error("Your session has expired. Please log in again.");
      Cookies.remove("token");
      Cookies.remove("user_id");
      Cookies.remove("user_full_name");
      window.location.href = '/auth/login';
    } else if (status === 503) {
      // console.log("Service Unavailable");
      toast.error(
        "The service is temporarily unavailable. Please try again later."
      );
      Cookies.remove("token");
      Cookies.remove("user_id");
      Cookies.remove("user_full_name");
      window.location.href = "/403";
    }
    // else if (error.request) {
    //   console.log(error.request);
    //   console.log('Network Error');
    //   toast.error("Network error. Please check your connection.");
    // } else {
    //   console.log('Error', error.message);
    //   toast.error("An error occurred. Please try again.");
    // }

    return Promise.reject(error);
  }
);
